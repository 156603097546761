
    import Vue from 'vue';
    import Component from 'vue-class-component';

    @Component
    export default class ViewFaq extends Vue {
        private sections = [
            {
                title: 'Qu\'est ce que Vetolib ?',
                content: `<b>Vous êtes propriétaire d’un animal  ?</b><br>
                Vetolib vous permet de trouver en un instant le vétérinaire le plus disponible et le plus proche
                de chez vous.
                Vous pouvez dès maintenant prendre rendez-vous directement en ligne, 24h/24 et 7j/7 en quelques
                secondes !<br><br>
                <b>Vous êtes vétérinaire ?</b><br>
                Vetolib est là pour vous aider !<br>
                Vetolib a développé un service complet dédié à la profession vétérinaire qui permet simultanément
                d’attirer de nouveaux clients, de réduire les rendez-vous non honorés et de gagner du temps à la faveur
                d’une solution complète de gestion de vos rendez-vous et du développement de votre clientèle.`,
            },
            {
                title: 'Vetolib respecte t-il la confidentialité de mes données personnelles ? ',
                content: `Tout est mis en oeuvre pour sécuriser toutes les informations lors de vos connections.<br>
C’est l’une de nos priorités : Nous prenons très au sérieux la confidentialité de nos clients et des utilisateurs de
Vetolib. A aucun moment, nous ne transmettrons vos données personnelles à des tiers.`,
            },
            {
                title: 'Est-ce que le service Vetolib est gratuit pour les propriétaires d’animaux ? ',
                content: `Absolument ! Vous pouvez prendre rendez-vous gratuitement sur le site.`,
            },
            {
                title: 'Je suis Docteur Vétérinaire : Comment m’inscrire ? ',
                content: `Rien de plus simple : Il vous suffit de
<a href="https://pro.vetolib.fr/" target="_self">cliquer ici !</a><br>
                Pour vous inscrire, renseignez simplement les informations demandées, puis cliquez sur
                "S’inscrire maintenant".`,
            },
            {
                title: 'Comment prendre un rendez-vous avec Vetolib ? ',
                content: `Rien de plus facile !<br>
                Vous devez tout simplement suivre ces 3 étapes :<br>
                Rechercher un vétérinaire soit en saisissant la localité, le nom de votre vétérinaire ou
                l’établissement soit par la spécialité.<br>
                Choisissez le vétérinaire puis cliquez sur l'horaire qui vous convient.<br>
                Renseignez le ou les noms de vos animaux ainsi que le motif de consultation puis cliquez sur "valider
                la demande de rendez-vous".<br>
                Votre rendez-vous est maintenant pris !<br>
                Un email de confirmation vous sera alors envoyé ainsi qu’un SMS de rappel la veille du rendez-vous.`,
            },
            {
                title: 'Comment accéder à l’historique de mes rendez-vous ?',
                content: `Connectez-vous à votre compte sur vetolib.fr en cliquant sur "connexion" ou sur
votre nom si vous êtes déjà connecté.<br>
                Accédez à la rubrique "Mes rendez-vous".`,
            },
            {
                title: 'Comment annuler un rendez-vous ?',
                content: `Vous pouvez annuler un rendez-vous jusqu’à 4h avant l’horaire prévue. Passé ce délai, il vous
faut contacter le vétérinaire par téléphone pour annuler le rendez-vous. <br>
                Connectez-vous à votre compte sur vetolib.fr en cliquant sur "connexion" ou sur votre nom si
                vous êtes déjà connecté. <br>
                Accédez à la rubrique  "Mes rendez-vous" et cliquez en face du rendez-vous concerné sur le bouton
                "Annuler ce rendez-vous".<br>
                Votre rendez-vous est annulé et la mention "annulé" apparaîtra en face du rendez-vous concerné dans
                l'historique "Mes rendez-vous".`,
            },
            {
                title: 'Comment modifier mes informations personnelles ?',
                content: `Pour apporter des modifications sur votre compte ou en ajouter :<br>
                Connectez-vous à votre compte sur vetolib.fr en cliquant sur “connexion” ou sur votre nom si
                vous êtes déjà connecté. <br>
                Accédez à la rubrique  "Mon profil". <br>
                Les éléments suivant peuvent être modifiés :<br>
                Votre ou vos animaux.<br>
                Votre adresse e-mail.<br>
                Votre mot de passe.<br>
                Votre date de naissance.<br>
                Votre adresse postale.<br>
                Votre numéro de téléphone.<br>
                Lorsqu'un ou plusieurs éléments ont été modifiés ou ajoutés, cliquez sur "Enregistrer".<br>
                Pour modifier votre nom et prénom, merci de nous contacter via le formulaire de contact
                <a href="/nous-contacter">ici</a>.`,
            },
            {
                title: 'J\'ai oublié mon mot de passe. Comment le réinitialiser ?',
                content: `Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser
                en suivant les étapes suivantes :<br>
                Accèdez à la page "Reinitialiser mon mot de passe" en cliquant
                <a href="/reinitialiser"">ici</a>.<br>
                Saisissez votre adresse e-mail et cliquez sur "Envoyer".<br>
                Un email avec un mot de passe provisoire vous sera transmis.<br>
                Vous pourrez ensuite modifier le mot de passe en Accédez à la rubrique "Mon profil" puis
                "modifier mon mot de passe".`,
            },
        ];
    }
